

.freeHeroTitle{
  background-color: #037689;
  height: 60px;
  width: 100%;
}

.freeHeroTitle h2{
  color: #fff;
  padding: 0;
  margin: 0;
  font: 12px;
}

label{
  color: #fff;
  font-weight: bold;
}

.freeHeroTitle p{
  margin-top: 88%;
  color: #fff;
  font-size: 12px;
}

.freeHero {
  position: relative;
  width: 100%;
}

.heroImage {
  width: 100%;
  height: auto;
}


.personal-info-heading {
  color: #037689;
}

.personal-info-form {
  max-width: 500px;
  margin: 0 auto;
}

.form-container{
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-top: 100px;
}

.form-group-row {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.check-box-main{
  display: 'flex';
  flex-direction: column;
  justify-content: center;
  align-content: center;
}

.check-box-container{
  display: flex;
  flex-direction: column;
  justify-content: center; 
  align-items: flex-start;
}

.check-box-container div{
  display: flex; 
  margin-top: 10px;
  justify-content: center;
  align-items: center;
}


input{
  margin-left: 10px;
}

select{
  margin-left: 10px;
}

.form-group{ 
  margin-bottom: 20px;
}

.form-label {
  display: block;
  font-weight: bold;
  color: #fff;
}

.form-control {
  width: 100%;
  height: 40px;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
}


.submit-button {
  background-color: #037689;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.submit-button:hover {
  background-color: #037680;
}

@media(max-width: 575px){
  
  .form-group-row div{
    flex-direction: column;
  }
  .form-group-row input{
    width: 100%;
  }

  input{
    max-width: 150px;
  }

  select{
    max-width: 150px;
  }

  textarea{
    max-width: 150px;
  }
}

@media(min-width: 830px){
  .check-box-main{
    flex-direction: row;
  }

  .check-box-container{
    justify-content: flex-start;
    align-items: flex-start;
  }
  
  .freeHero{
    width: 100%;
    height: auto;
    background-image: linear-gradient(to bottom, #037689 80%, #1d2228);
  }

  .freeHeroTitle{
    position: relative;
    top: 100px;
    left: 8%;
    width: 92%;
  }

  .freeHero img.heroImage{
    position: relative;
    top: 50px;
    left: 25%;
    width: 40%;
    height: auto;
  }

  .freeHeroTitle h2{
    font-size: 40px;
    text-align: left;
  }

  .freeHeroTitle p{
    margin-top: 10px;
    font-size: 20px;
    width: 35%;
    text-align: left;
  }

}


@media(min-width: 480px) and (max-width: 830px){
  .check-box-main{
    flex-direction: row;
  }

  .check-box-container{
    justify-content: flex-start;
    align-items: flex-start;
  }
  
  .freeHero{
    width: 100%;
    height: auto;
    background-color: #037689;
  }

  .freeHeroTitle{
    position: relative;
    top: 95px;
    left: 8%;
    width: 92%;
  }

  .freeHero img.heroImage{
    position: relative;
    top: 50px;
    left: 25%;
    width: 40%;
    height: auto;
  }

  .freeHeroTitle h2{
    font-size: 20px;
    text-align: left;
  }

  .freeHeroTitle p{
    margin-top: 10px;
    font-size: 10px;
    width: 35%;
    text-align: left;
  }

  .form-label{
    max-width: 80%;
  }
}
