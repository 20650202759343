.corner-guide {
    display: flex;
    justify-content: space-evenly;
    position: fixed;
    bottom: 30px;
    right: 30px;
    background-color: #000;
    padding: 10px;
    border-radius: 20px;
    cursor: pointer;
    transition: width 0.3s ease;
    width: 10%;
    opacity: 0.5;
  }

  .extraDiv{
    background-color: transparent;
    height: 115px;
    display: flex;
    width: 100%;
    justify-content: space-evenly;
    position: fixed;
    bottom: 5px;
  }
  
  .corner-guide.hovered {
    width: 95%;
    opacity: 1;
  }
  
  .guide-text {
    margin: 0;
  }
  
  .corner-guide a {
    display: block;
    text-decoration: none;
    color: #fff;
    margin-bottom: 5px;
  }

  .guideButton{
    display: none;
  }
  
@media(max-width: 900px){
    .corner-guide{
        right: 10px;
    }
}

@media(max-width: 700px){
    .corner-guide{
        display: none;
    }

    .extraDiv{
        display: none;
    }

    .guideButton{
        display: flex;
        justify-content: space-evenly;
        position: fixed;
        bottom: 30px;
        right: 30px;
        background-color: #000;
        padding: 10px;
        border-radius: 20px;
        cursor: pointer;
        width: 10%;
        opacity: 0.5;
    }

    .guideButton button{
        background-color: #000;
        border: none;
        color: #fff;
    }

    .menu{
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        position: fixed;
        bottom: 0px;
        right: 0px;
        background-color: #000;
        padding: 10px;
        cursor: pointer;
        transition: width 0.3s ease;
        width: 20%;
        height: 100%;
        opacity: 0.5;
    }

    .menu button{
        background-color: #000;
        border: none;
        color: #fff;
    }
    .menuItems{
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
    }

    .menuItems a{
      color: #fff;
      text-decoration: none;
      margin-top: 15%;
    }
}