.about-container {
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
    font-size: 20px;
  }
  
  .about-container h1 {
    text-align: center;
  }

  .about-container img {
    width: 10%;
    max-height: 200px;
    object-fit: cover;
    margin-left: 10px;
  }
  
  .image-container-about {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  
  .image-container-about img {
    width: 30%;
    max-height: 200px;
    object-fit: cover;
  }
  
 .about-container p {
    margin-bottom: 10px;
  }

  .selectedImg img{
    width: 80%;
    height: 80%;
    max-height: 200px;
    object-fit: cover;
  }
  
  @media (max-width: 600px) {
    .about-container {
      font-size: 16px;
    }
  
    .about-container h1 {
      font-size: 30px;
    }
  
    .about-container p {
      font-size: 16px;
    }
  
    .image-container-about {
      flex-direction: column;
    }
  
    .image-container-about img {
      width: 100%;
      margin-bottom: 10px;
    }
  }