body{
  background-color: #1d2228;
}

.guide{
  position: fixed;
  color: #fff;
  bottom:20px;
  right: 100px;
  background-color: black;
  width: 120px;
  height: 50px;
  border-radius: 50px;
  opacity: 50%;
}

.mainHomeContainer{
  flex-direction: column; 
  align-items: center;
  width: 100%;
  margin: auto;
  color: #fff;
  margin-bottom: 10%;
  max-width: 1200px;
}

.hero-section {
    position: relative;
    height: 500px;
  }

  
  .hero-section img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .hero-overlay {
    position: absolute;
    top: 50px;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom, transparent 60%, #037689 90%);
  }

  .transition-div {
    position: absolute;
    top: 548px;
    left: 0;
    width: 100%;
    height: 50%;
    background: linear-gradient(to bottom, #037689 60%, #1d2228 90%);
  }
  
  .hero-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    position: absolute;
    top: 85%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: left;
    color:#1d2228;
  }

  .hero-content img{
    width: 80%;
    height: 80%;
  }

  .hero-content a{
    text-decoration: none; 
    color: #fff;
  }

  .hero-content a:hover{
     opacity: 0.5;
  }
  
  .hero-content h1 {
    font-size: 2rem;
    margin-top: 20rem;
    text-align: center;
  }
  
  .hero-content h3 {
    font-size: 1rem;
    text-align: center;
  }

  .hero-content p {
    font-size: 1.5rem;
    color:#fff;
    z-index: 100;
    text-align: center;
  }

  .mainHomeContainer h3 { 
    font-size: 3rem;
  }

  .freeEstimate{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 200px;
    width: 100%;
  }

  .freeEstimate p{
    width: 80%;
  }

  .image-grid{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
   
  }
  
  
  .image-grid a {
    display: block;
    position: relative;
    width: 80%;
    height: 90%;
  }
  
  .image-grid img {
    display: block;
    width: 100%;
    height: auto;
    margin-bottom: 50px;
  }
  
  .image-grid p {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 90%;
    padding: 11px;
    margin: 0;
    background-color: rgba(0, 0, 0, 0.8);
    color: #fff;
    font-size: 14px;
    text-align: center;
    opacity: 0;
    transition: opacity 0.3s ease;
    margin-bottom: 3%;
  }
  

  .image-grid img{
    aspect-ratio: 1/0.8;
  }

  .infoRec{
    position:absolute;
    display: flex;
    left: 0;
    align-items: center;
    justify-content: space-evenly;
    height: 200px;
    width: 100%;
    margin-top: 100px;
    background: linear-gradient(to top right, #037680 25%, #1d2228 );
  }


  .infoDiv{
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .infoDiv:nth-child(1){
    display: none;
  }
  
  .infoDiv:nth-child(2){
    display: none;
  }


  .infoDiv h3{
    padding: 0%;
    margin-bottom: 0%;
    margin-top: 0%;
  }

  .infoDiv p{
    width: 50%;
    text-align: center;
    margin-top: 0%;
  }
  

  .container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin-top: 300px;
  }
  
  .text-container {
    flex: 1 0 100%;
    padding: 20px;
    box-sizing: border-box;
    text-align: center;
  }
  
  .image-container {
    flex: 1 0 100%;
    padding: 20px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .image-hover:hover{
    opacity: 0.5;
  }
  
  @media (min-width: 768px) {

    .hero-content img{
      width: 80%;
      height: 80%;
    }
    
    .container{
      margin-top: 500px;
    }

    .text-container {
      flex: 1 0 50%;
    }
  
    .image-container {
      flex: 1 0 50%;
    }

    .text-container h2{
      text-align: center;
    }
  
    .text-container p{
      text-align: center;
      width: 100%;
    }
  }
  
  .text-container h2 {
    font-size: 40px;
    margin-bottom: 10px;
    text-align: left;
  }
  
  .text-container p {
    font-size: 20px;
    line-height: 1.5;
    text-align: left;
    width: 60%;
  }
  
  .image-container img {
    max-width: 100%;
    max-height: 100%;
  }
  



  @media(min-width: 480px){

    .hero-content img{
      width: 35%;
      height: 35%;
    }

      
    .image-grid {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 30px;
      width: 90%;
   
    }
    
    .image-grid img {
      display: block;
      width: 100%;
      height: auto;
    }

    .image-grid a{
      width: 100%;
    }

    .image-grid p{
      width: 95%;
    }

    .infoDiv:nth-child(2){
      display: flex;
    }

    .Gallery-grid {
      display: grid;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 50px;
      width: 80%;
    }


    .Gallery-grid-Second {
      margin-top: 6%;
      display: flex;
    }
  }

  .footer{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    background-color: #1d2228;
    height: 200px;
    width: 100%;
    font-size: 20px;
  }

  @media(min-width: 800px){

    .hero-content h1 {
      font-size: 5rem;
      margin-top: 25rem;
    }
    
    .hero-content h3 {
      font-size: 2rem;
    }
  
    .hero-content p {
      font-size: 2rem;
      margin-top: -2rem;
      color:#fff
    }
    
    .infoDiv:nth-child(1){
      display: flex;
    }
  }

  @media (max-width: 950px){

    .footer{
      font-size: 12px;
    }
    .footer img{
      width: 30%;
    }
  }

  @media (max-width: 600px){

    .footer{
      font-size: 8px;
    }
    .footer img{
      width: 20%;
    }
  }
