body{
    color: #fff;
}

.galleryMainDiv{
    flex-direction: column; 
    align-items: center;
    width: 100%;
    margin: auto;
    color: #fff;
    margin-bottom: 10%;
    max-width: 1200px;
}

.container-reverse{
    display: flex;
    flex-wrap: wrap;
    flex-direction: row-reverse;
    justify-content: center;
    align-items: center;
    margin-top: 60px;
}

.image-container-three {
    display: flex;
    justify-content: space-between;
    margin-top: 200px;
    margin-bottom: 200px;
    width: 100%;
  }

  .image-container-three img {
    width: 30%;
    max-height: 200px;
    object-fit: cover;
  }